<template>
  <FollowedOrderDetail
    v-if="order !== null"
    :order="order"
  ></FollowedOrderDetail>
</template>

<script>
import FollowedOrderDetail from '@/components/GroupList/FollowedOrderDetail';
import { getFollowedGroupDetail } from '@/api/group-buying';
export default {
  components: {
    FollowedOrderDetail
  },
  data() {
    return {
      order: null
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    orderId() {
      return this.$route.params.order_id;
    }
  },
  watch: {
    orderId: {
      immediate: true,
      handler() {
        this.setData();
      }
    }
  },
  methods: {
    setData() {
      getFollowedGroupDetail(this.orderId)
        .then(data => {
          this.order = data;
        })
        .catch(() => {
          this.$router.push({
            name: 'FollowedList',
            params: {
              group_id: this.groupId
            }
          });
        });
    }
  }
};
</script>
