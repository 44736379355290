<template>
  <div class="main-content">
    <div class="product-info-wrapper">
      <div class="content-block">
        <div class="container">
          <ul class="group-datas">
            <li item="訂購時間">
              <div class="text">{{ order.created_at }}</div>
            </li>
            <li item="付款方式">
              <div class="text">{{ order.payment_method }}</div>
            </li>
            <li
              item="匯款後五碼"
              v-if="order.payment_method_name === 'Transfer'"
            >
              <div class="text">{{ order.bankno }}</div>
            </li>
            <li item="領貨方式">
              <div class="text">{{ order.delivery_type_display }}</div>
            </li>
            <template v-if="order.member_contact">
              <li item="收件人">
                <div class="text">
                  {{ order.member_contact.name }}
                </div>
              </li>
              <li item="連絡電話">
                <div class="text">{{ order.member_contact.phone }}</div>
              </li>
              <li item="手機號碼">
                <div class="text">{{ order.member_contact.mobile }}</div>
              </li>
              <li item="取貨地址">
                <div class="text">{{ order.member_contact.address }}</div>
              </li>
              <li item="備註">
                <div class="text">{{ order.member_contact.remark }}</div>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="content-block">
        <div class="container">
          <template v-for="(item, index) in groupBuyingOrderDetails">
            <div :key="index">
              <div class="product-item sm">
                <div class="productimg">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="product-info-wrap">
                  <div class="product-info">
                    <div class="product-name">
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="order-detail">
                    <ul class="order-items">
                      <li>
                        訂購數量
                        <span>{{ item.quantity }}</span>
                        /{{ item.unit }}
                      </li>
                      <li>
                        實際數量
                        <span>{{ item.actual_quantity }}</span>
                        /{{ item.unit }}
                      </li>
                    </ul>
                    <div class="order-price">${{ item.sale_price }}</div>
                  </div>
                </div>
              </div>
              <ul class="total-area" v-if="item.details.length > 0">
                <li
                  v-for="detail in item.details"
                  :key="detail.id"
                  :item="detail.spec_name"
                >
                  <span>{{ detail.actual_quantity }}</span>
                </li>
              </ul>
            </div>
          </template>
          <ul class="total-area" style="border-top: 1px solid #D8D8D8;">
            <li item="小計">
              <span>$ {{ order.subtotal }}</span>
            </li>
            <li item="服務費">
              <span>$ {{ order.handling_fee }}</span>
            </li>
            <li class="total" item="總計">
              <span>${{ order.total_price }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy, sumBy } from 'lodash';

export default {
  props: {
    order: {
      required: true,
      type: Object
    }
  },
  computed: {
    groupBuyingOrderDetails() {
      let details = [];
      let groupByMainProduct = groupBy(
        this.order.group_buying_order_details,
        'main_product_id'
      );
      Object.keys(groupByMainProduct).forEach(key => {
        let group = groupByMainProduct[key];

        //不是規格商品
        if (key === null) {
          group.forEach(ele => {
            details.push(ele);
          });
        } else {
          details.push({
            actual_quantity: sumBy(group, 'actual_quantity'),
            cover: group[0].cover,
            group_buying_product_id: group[0].main_product_id,
            id: group[0].id,
            member: group[0].member,
            name: group[0].name,
            origin_actual_quantity: sumBy(group, 'origin_actual_quantity'),
            quantity: sumBy(group, 'quantity'),
            subtotal: sumBy(group, 'subtotal'),
            sale_price: group[0].sale_price,
            unit: group[0].unit,
            unit_price: group[0].unit_price,
            details: group
          });
        }
      });

      return details;
    }
  }
};
</script>
